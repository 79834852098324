// src/data.js
export const doorsData = [
  {
    id: 1,
    image: "/doors/design1.jpg",
    name: "Design 1",
    price: 15000,
  },
  {
    id: 2,
    image: "/doors/design2.jpg",
    name: "Design 2",
    price: 16000,
  },
  {
    id: 3,
    image: "/doors/design3.jpg",
    name: "Design 3",
    price: 17000,
  },
  {
    id: 4,
    image: "/doors/design4.jpg",
    name: "Design 4",
    price: 15500,
  },
  {
    id: 5,
    image: "/doors/design5.jpg",
    name: "Design 5",
    price: 16500,
  },
  {
    id: 6,
    image: "/doors/design6.jpg",
    name: "Design 6",
    price: 18000,
  },
  {
    id: 7,
    image: "/doors/design7.jpg",
    name: "Design 7",
    price: 17500,
  },
  {
    id: 8,
    image: "/doors/design8.jpg",
    name: "Design 8",
    price: 18500,
  },
  {
    id: 9,
    image: "/doors/design9.jpg",
    name: "Design 9",
    price: 19000,
  },
  {
    id: 10,
    image: "/doors/design10.jpg",
    name: "Design 10",
    price: 19500,
  },
  {
    id: 11,
    image: "/doors/design11.jpg",
    name: "Design 11",
    price: 20000,
  },
  {
    id: 12,
    image: "/doors/design12.jpg",
    name: "Design 12",
    price: 20500,
  },
  {
    id: 13,
    image: "/doors/design13.jpg",
    name: "Design 13",
    price: 21000,
  },
  {
    id: 14,
    image: "/doors/design14.jpg",
    name: "Design 14",
    price: 21500,
  },
  {
    id: 15,
    image: "/doors/design15.jpg",
    name: "Design 15",
    price: 22000,
  },
  {
    id: 16,
    image: "/doors/design16.jpg",
    name: "Design 16",
    price: 22500,
  },
  {
    id: 17,
    image: "/doors/design17.jpg",
    name: "Design 17",
    price: 23000,
  },
  {
    id: 18,
    image: "/doors/design18.jpg",
    name: "Design 18",
    price: 23500,
  },
  {
    id: 19,
    image: "/doors/design19.jpg",
    name: "Design 19",
    price: 24000,
  },
  {
    id: 20,
    image: "/doors/design20.jpg",
    name: "Design 20",
    price: 25000,
  },
];
